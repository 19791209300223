<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>对账报表</el-breadcrumb-item>
            <el-breadcrumb-item to="/Sales_details/house_rate" v-if="state == 'house_rate'" >房费销售明细</el-breadcrumb-item>
            <el-breadcrumb-item to="/Sales_details/pre_sale_coupon" v-if="state == 'pre_sale_coupon'" >预售券销售明细 </el-breadcrumb-item>
            <el-breadcrumb-item>详情</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button class="bg-gradient" type="primary" @click="goBack">返回</el-button>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="content-box">
         <el-table
               v-loading="loading"
               :data="tableData"
               border fit
               style="width: 100%"
               :stripe="true"
               :header-cell-style="{background: '#f5f8f9'}">
            <el-table-column label="序号" type="index" width="60"></el-table-column>
            <el-table-column label="订单编号" min-width="120" prop="orderNo"></el-table-column>
            <el-table-column :label="state == 'house_rate' ? '房间类型' : state == 'commodity' ? '商品类型数' : '预售券名称' " prop="roomTypeName"></el-table-column>
            <el-table-column :label="state == 'house_rate' ? '数量' : state == 'commodity' ? '商品数量' : '售出数量' " prop="roomNum"></el-table-column>
            <el-table-column :label="state == 'house_rate' ? '姓名' : state == 'commodity' ? '收货人' : '联系人' " prop="reserveName"></el-table-column>
            <el-table-column label="手机号" prop="reserveMobile"></el-table-column>
            <el-table-column :label="state == 'house_rate' ? '入住时间' : '下单时间' " prop="checkInTime"></el-table-column>
            <el-table-column label="销售额" prop="actualAmount"></el-table-column>
            <el-table-column label="实际收入" prop="totalMoney"></el-table-column>
         </el-table>
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
   </section>
</template>

<script>
import { urlObj , business } from '@/api/interface'
import {reconciliation} from "@/api/interface/business";
import {mapState} from "vuex";
export default {
   name: "checkHouse",
   data(){
      return{
         loading:true,             // 表格加载效果
         tableData: [],             // 表格数据
         state:'',                  //哪个页面进入
         date:'',                   //传递过来的日期
         total: 0,                  // 用户列表总条目数
         page: 1,                   // 当前页 默认第一页
         limit: 0,                 // 每页显示数
      }
   },
   computed: {
       ...mapState(['hotelInfo'])
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id) {
               this.getList()
            }
         },
         deep: true
      }
   },
   mounted() {

      let houseDetaInfo = JSON.parse(sessionStorage.getItem('houseDetaInfo'))
     this.storeId = houseDetaInfo.id
     this.date = houseDetaInfo.date
      this.state = houseDetaInfo.state
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getList()
   },
   beforeDestroy() {
      sessionStorage.removeItem('houseDetaInfo')
   },
   methods:{
      //获取表格数据
      getList(){
         let url = ''
         let param = {}
         if(this.state == 'house_rate'){
           url = reconciliation.getRoomRateDetailInformation
            param = {
               limit:this.limit,
               page:this.page,
               date:this.date,
               hotelId:this.hotelInfo.id
            }
           if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
             param.hotelId = this.storeId
             param.storeId = this.hotelInfo.storeId
           }
            this.$axios.post(url,param).then(res => {
               if (res.success) {
                  this.loading = false
                  this.tableData = res.records
                  this.total = res.total
               }
            })
         }else if(this.state == 'commodity'){
            // PENDING_PAYMENT
            console.log("commodity")
         }else{
           url = reconciliation.getStatisticsCouponMyselfList
           param = {
               limit:this.limit,
               page:this.page,
               date:this.date,
               beginDate:this.date,
               hotelId:this.hotelInfo.id,
           }
           if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
             param.hotelId = this.storeId
             param.storeId = this.hotelInfo.storeId
           }
            this.$axios.post(url,param).then(res => {
               if (res.success) {
                  this.loading = false
                  // this.tableData = res.records
                  let arr = []
                  let reserveName = ''
                  console.log(res.records)
                  res.records.forEach( item =>{
                     if(item.realName){
                        reserveName = item.realName + item.memberId
                     }else{
                        reserveName = item.memberId
                     }
                     arr.push(
                        Object.assign({
                           orderNo:item.orderId,         //订单编号
                           roomTypeName:item.mainTitle,  // 预售券名称
                           roomNum:1,                    // 售出数量
                           reserveName:reserveName,      // 联系人
                           reserveMobile:item.mobile,    // 手机号
                           checkInTime:item.buyingTime,  // 下单时间
                           totalMoney:item.actualAmount, //实际收入
                           actualAmount:item.orderTotalPrice,// 销售额
                        })
                     )
                  })
                  this.tableData = arr
                  this.total = res.total
               }
            })
         }
      },
      // 取消/返回上页
      goBack(){
         this.$router.go(-1)
      },
      // 改变每页数
      pageChange(num) {
         this.limit = num
         this.getList()
      },
      // 改变当前页
      handlePaging(num) {
         this.page = num
         this.getList()
      },
   },
}
</script>

<style scoped>

</style>
